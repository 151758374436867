import React from "react";

export default function WebIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="24" fill="#D8FBFE" />
      <g clipPath="url(#clip0_0_1)">
        <path
          d="M33.41 16.91L29.09 12.59C28.9039 12.4028 28.6827 12.2543 28.439 12.1531C28.1952 12.0518 27.9339 11.9998 27.67 12H16C15.4696 12 14.9609 12.2107 14.5858 12.5858C14.2107 12.9609 14 13.4696 14 14V34C14 34.5304 14.2107 35.0392 14.5858 35.4142C14.9609 35.7892 15.4696 36 16 36H32C32.5305 36 33.0391 35.7892 33.4142 35.4142C33.7893 35.0392 34 34.5304 34 34V18.33C34.0002 18.0661 33.9482 17.8047 33.8469 17.561C33.7457 17.3173 33.5972 17.0961 33.41 16.91ZM22.57 26.59L20.07 29.09C19.8787 29.2538 19.6326 29.3394 19.3809 29.3297C19.1293 29.32 18.8905 29.2157 18.7124 29.0376C18.5343 28.8595 18.43 28.6208 18.4203 28.369C18.4106 28.1173 18.4962 27.8713 18.66 27.68L20.27 26.06C20.2939 26.0375 20.313 26.0104 20.326 25.9804C20.3391 25.9502 20.3457 25.9178 20.3457 25.885C20.3457 25.8522 20.3391 25.8197 20.326 25.7896C20.313 25.7596 20.2939 25.7324 20.27 25.71L18.66 24.09C18.4962 23.8987 18.4106 23.6526 18.4203 23.401C18.43 23.1493 18.5343 22.9106 18.7124 22.7324C18.8905 22.5544 19.1293 22.45 19.3809 22.4403C19.6326 22.4305 19.8787 22.5162 20.07 22.68L22.57 25.18C22.7562 25.3674 22.8608 25.6208 22.8608 25.885C22.8608 26.1492 22.7562 26.4026 22.57 26.59ZM28.5 26H25C24.7348 26 24.4804 25.8947 24.2929 25.7071C24.1053 25.5196 24 25.2653 24 25C24 24.7348 24.1053 24.4804 24.2929 24.2929C24.4804 24.1054 24.7348 24 25 24H28.5C28.7652 24 29.0196 24.1054 29.2071 24.2929C29.3946 24.4804 29.5 24.7348 29.5 25C29.5 25.2653 29.3946 25.5196 29.2071 25.7071C29.0196 25.8947 28.7652 26 28.5 26Z"
          fill="#25B5D2"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_1">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(12 12)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
