import {
  LIspan,
  LearnCardSub,
  LearnCards,
  LearnCardsInner,
  LearnCardsTitle,
  LearnHeading,
  LearnInnerText,
  LearnSkillsHeader,
  LearnSkillsMain,
} from "../../StyledComponents/LandingStyled";
import React from "react";
import BrandingIcon from "../svg/BrandingIcon";
import ProductDesignIcon from "../svg/ProductDesignIcon";
import WebIcon from "../svg/WebIcon";
import { Container, List } from "reactstrap";
import { LearnSkillsArray } from "../../DummyData";
import { motion } from "framer-motion";
import { AnimationOnScroll } from "react-animation-on-scroll";

export default function LearnSkills() {
  const customEase = [0, 0, 0.5, 1]; // cubic-bezier parameters

  // Safely format the FAQs, checking if `faq.name` exists
  const formattedFaqs = LearnSkillsArray.map((faq) => ({
    ...faq,
    name: faq.name ? faq.name.replace(/\n/g, "<br />") : "", // Replace line breaks or default to an empty string
  }));

  return (
    <Container className="p-b-section">
      <LearnSkillsMain>
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
          <LearnSkillsHeader>
            <LearnHeading>
              Learn the skills of our specialists who will be your partners.
            </LearnHeading>
            <LearnInnerText>
              Remember that during the project you will always receive our
              substantive and technical support.
            </LearnInnerText>
          </LearnSkillsHeader>
        </AnimationOnScroll>
        <motion.div
          initial={{ opacity: 0, y: 25 }}
          transition={{
            duration: 1,
            ease: customEase,
            delay: 0.0,
          }}
          whileInView={{ opacity: 1, y: 0 }}
        >
          <LearnCards>
            {formattedFaqs.map((faq) => (
              <LearnCardsInner key={faq.id}>
                {faq.id === "1" && <BrandingIcon />}
                {faq.id === "2" && <ProductDesignIcon />}
                {faq.id === "3" && <WebIcon />}
                <LearnCardsTitle>{faq.title}</LearnCardsTitle>
                <LearnCardSub>{faq.subInner}</LearnCardSub>
                <List>
                  {faq.list &&
                    faq.list.map((item, index) => (
                      <li key={index}>
                        <LIspan bg={faq.color}></LIspan>
                        <span
                          dangerouslySetInnerHTML={{ __html: item.name }}
                        ></span>
                      </li>
                    ))}
                </List>
              </LearnCardsInner>
            ))}
          </LearnCards>
        </motion.div>
      </LearnSkillsMain>
    </Container>
  );
}
